import { capFirst, gtag } from "#js/components/utils"
import { css, html } from "lit"
import { msg, str, updateWhenLocaleChanges } from "@lit/localize"
import { TrackingMixin } from "#js/components/TrackingMixin"
import { getUrlWithTracking } from "#js/components/utm"
import { picture } from "#js/components/ui"
import { sendAlgoliaClickEvent } from "#js/integrations/algoliaSearch"
import { setLocale } from "#js/components/lit-i18n"
import { unsafeHTML } from "lit/directives/unsafe-html.js"

export class OfferCard extends TrackingMixin {
  static get properties() {
    return {
      offer: { type: Object },
    }
  }

  constructor() {
    super()
    setLocale(globalThis.language)
    updateWhenLocaleChanges(this)
  }

  static styles = css`
    .job-title {
      font-size: 80%;
      margin: 0 0 0.5em;
      color: var(--black-50);
    }
  `

  shouldShowHostPicture() {
    return this.offer.host_picture && this.offer.activity_type !== "counselling"
  }

  csrfToken = document.querySelector("input[name=csrfmiddlewaretoken]").value

  render() {
    if (!this.offer) {
      console.warn("<offer-card> component rendered without properties assigned.")
      // Component was loaded into the dom, but has no properties assigned.
      // This happens when it is contained in another component or renderer.
      return
    }
    return html`
      <link rel="stylesheet" href="${globalThis.styleFilePath}">
      <a
        href="${getUrlWithTracking(this.offer.public_url, this.trackingInformation)}"
        @click="${(event) => this.sendSelectItemOfferCard(event, this.offer)}"
        class="card ${this.getCardType()}">
        <div class="card__layer card__layer--offer">
          ${this.getPicture()}
          ${this.getHostPicture()}
          <div class="ribbon__wrapper">
            ${this.getRibbon()}
          </div>
          ${this.getWatchlist()}
          ${this.getDebugInfo()}
        </div>
        <div class="card__content card__content--offer">
          ${this.getTimer()}
          <section class="column column--spaced" style="height: 100%; gap: 1em;">
            <div>
              ${this.getTitle()}
              ${this.getSubtitle()}
            </div>
            ${this.getBubbles()}
          </section>
        </div>
      </a>
    `
  }

  getCardType() {
    if (this.offer.type === "expert") {
      return "card--expert"
    } else {
      return "card--offer"
    }
  }

  getTitle() {
    if (this.offer.type === "expert") {
      return html`
        <h4 style="margin-block-end: 0">${this.offer.title}</h4>
        <p class="hyphenate job-title">${this.offer.job_title}</p>
      `
    } else {
      return html`
        <h4>${this.offer.title}</h4>
      `
    }
  }

  getSubtitle() {
    if (this.offer.type === "expert") {
      return html`
        <p class="hyphenate">${this.offer.areas_of_expertise}</p>
      `
    } else {
      return html`
        <p class="hyphenate ellipsis" style="max-height: 6em; overflow: hidden">${this.offer.subtitle}</p>
      `
    }
  }

  squarePictureTypes = ["expert", "host"]

  getPicture() {
    if (this.offer.picture) {
      return html`
        ${
        unsafeHTML(picture(
          this.offer.picture,
          this.offer.title,
          this.squarePictureTypes.includes(this.offer.type) ? "1/1" : "3/2",
          {
            style: `width: 100%; filter: ${
              this.shouldShowHostPicture() ? "opacity(0.3)" : "none"
            }`,
          },
          { class: "card__image card__image--offer", loading: "lazy" },
        ))
      }
      `
    }
  }

  getHostPicture() {
    if (this.shouldShowHostPicture()) {
      return html`
        ${
        unsafeHTML(picture(
          this.offer.host_picture,
          this.offer.provider_title,
          "1/1",
          { class: "host__image-wrapper" },
          {
            class: "host__image",
            loading: "lazy",
          },
        ))
      }
      `
    }
  }

  getRibbon() {
    if (this.offer.activity_type_display) {
      return html`
        <div class="ribbon">
          ${this.offer.activity_type_display}
        </div>
      `
    }
  }

  getBubbles() {
    return html`
      <div class="bubble__wrapper bubble__wrapper--offer">
        ${[...this.getLanguageTags()]}
        ${this.getLocationBubble()}
        ${this.getAgeBubble()}
      </div>
    `
  }

  getWatchlist() {
    const watchURLs = this.offer.watch_url && this.offer.unwatch_url
    if (watchURLs) {
      if (this.offer.watched) {
        return html`
          <div class="watchlist__wrapper">
            <div class="watchlist" @click="${this.unwatch}">
              <svg viewBox="0 0 125 108" class="watchlist__heart">
                <use href="${globalThis.svgSprite}#heartFilled"
                     style="color: var(--rose);"></use>
              </svg>
            </div>
          </div>
        `
      } else {
        return html`
          <div class="watchlist__wrapper">
            <div class="watchlist" @click="${this.watch}">
              <svg viewBox="0 0 125 108" class="watchlist__heart">
                <use href="${globalThis.svgSprite}#heart"></use>
              </svg>
            </div>
          </div>
        `
      }
    }
  }

  watch(event) {
    event.preventDefault()
    event.stopImmediatePropagation()
    this.offer.watched = true
    fetch(this.offer.watch_url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": this.csrfToken,
      },
    })
    gtag("add_to_wishlist", this.getGTMWishlistData())
    this.requestUpdate()
  }

  unwatch(event) {
    event.preventDefault()
    event.stopImmediatePropagation()
    this.offer.watched = false
    fetch(this.offer.unwatch_url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": this.csrfToken,
      },
    })
    gtag("remove_from_wishlist", this.getGTMWishlistData())
    this.requestUpdate()
  }

  getDebugInfo() {
    if (globalThis.debug) {
      return html`<small class="card__sort_order">${this.offer.sort_order}</small>`
    }
  }

  getTimer() {
    if (this.offer.booking_option_timer) {
      return html`
        <div class="card__timer card__timer--offer-card">
          <svg class="icon">
            <use href="${globalThis.svgSprite}#calendar"></use>
          </svg>
          <span>
            &nbsp;${capFirst(this.offer.booking_option_timer)}
            </span>
        </div>
      `
    }
  }

  *getLanguageTags() {
    for (
      const code of Object.keys(this.offer.languages).sort((a, b) =>
        b === globalThis.language ? 1 : -1
      )
    ) {
      yield html`
        <div class="bubble bubble--small">
          ${code.toUpperCase()}
        </div>
      `
    }
  }

  getLocationBubble() {
    if (this.offer.location && this.offer.location !== "online") {
      return html`
        <div class="bubble bubble--small">
          ${this.offer.location}
        </div>
      `
    }
  }

  getAgeBubble() {
    if (this.offer.age_range) {
      return html`
        <div class="bubble bubble--small">
          ${this.offer.age_range}
        </div>
      `
    }
  }

  getGTMWishlistData() {
    return {
      offer: {
        id: this.offer.id,
        title: this.offer.title,
        provider: this.offer.provider_title,
        activity_type: this.offer.activity_type,
      },
      wishlist_source: "card",
    }
  }

  sendSelectItemOfferCard(event, item) {
    gtag("event", "select_item", {
      items: [{
        item_name: item.title,
        item_id: item.id,
        item_brand: item.provider_title,
        affiliation: item.brand_name,
        quantity: 1,
        item_list_id: this.itemListId,
        item_list_name: this.itemListName,
      }],
    })

    sendAlgoliaClickEvent(item.algoliaAnalytics)
  }
}

globalThis.customElements.define("offer-card", OfferCard)
